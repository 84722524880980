.Main {
    color: aliceblue;
}

.JoinButton {
    background-color: #282c34;
    color: #ffffff;
    font-size: large;
    font-weight: bold;
    cursor: pointer;
    padding: 10px;
    border-radius: 5px;
    margin-top: 20px;
}