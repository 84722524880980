.Main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100vw;
}

.AskPrompt {
    font-size: large;
    font-weight: bold;
    margin: 10px;
}

.AskInput {
    width: 80%!important;
    min-height: 100px;
    padding: 10px;
    border-radius: 5px;
    margin: 10px;
    font-size: 12pt;
    resize: none;
    outline: none;
}

.AnswerPrompt {
    font-size: large;
    font-weight: bold;
    margin: 10px;
}

.AnswerInput {
    width: 80%!important;
    min-height: 100px;
    padding: 10px;
    border-radius: 5px;
    margin: 10px;
    font-size: 12pt;
    resize: none;
    outline: none;
}

.Back {
    cursor: pointer;
    margin-top: 100px;
    color: cadetblue;
    font-weight: normal;
}